<template>
  <section
    style="background-color: #FAFAFA; height: 100%"
    id="admin"
    class="pa-0"
  >
    <!-- Start alert button --->
    <v-btn
      elevation="10"
      :class="$vuetify.breakpoint.smAndUp ? 'quick_add_wrapper':'quick_add_wrapper_mobile'"
      fixed
      :color="timer_begun ? 'red-darken':'red'"
      :disabled="timer_begun"
      large
      rounded
      @click="add_outage = true">
      <v-layout
        row
        justify-center
        v-if="!timer_begun">
        <p
          v-if="$vuetify.breakpoint.smAndUp"
          class="alert_text"
          v-text="alert_text"
        />
        <v-icon color="white">
          mdi-alert
        </v-icon>
      </v-layout>
      <div
        v-else>
        <v-layout
          row
          justify-center>
          <p 
            style="margin: 5px; font-family: 'Open sans', sans serif; font-size:  20px; font-weight: bold; color: white !important"
            id="demo">
          </p>
        </v-layout>
      </div>
    </v-btn>
    <!-- End alert button --->

    <!-- Start quick add report -->
    <quick-add
      :bActive="add_outage"
      @close="add_outage = false"
    />
    <!-- End quick add reprort-->
    <operator-menu />
    <div
      :class="this.$vuetify.breakpoint.smAndUp ? 'section_division':'section_division_mobile'">
      <v-container
        class="view_conatiner">
        <v-slide-y-transition>
          <router-view />
        </v-slide-y-transition>        
      </v-container>
    </div>
  </section>
</template>

<script>
  export default {
    data: () => ({
      add_outage: false,
      alert_text: 'Report Outage',
      time_remaining: 0
    }),
    mounted () {
      try {
        var countDownDate = new Date(localStorage.getItem('date-start')).getTime()
        var now = new Date().getTime();
        if ((countDownDate - now) > 0) {
          this.set_timer()
        } else {
          localStorage.setItem('timer-status', 'false')
          localStorage.setItem('date-start', null)
        }
      }
      catch {
        localStorage.setItem('timer-status', 'false')
        localStorage.setItem('date-start', null)
      }
    },
    components: {
      OperatorMenu: () => import('@/components/sections/Operator/SideMenu'),
      QuickAdd: () => import('@/components/sections/Admin/ConfirmQuickAdd')
    },
    methods: {
      set_timer () {
        // Set the date we're counting down to
        var countDownDate = new Date(localStorage.getItem('date-start')).getTime();
        // Update the count down every 1 second
        var x = setInterval(function() {
          // Get today's date and time
          var now = new Date().getTime();
          // Find the distance between now and the count down date
          var distance = countDownDate - now;
          // Time calculations for days, hours, minutes and seconds
          var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((distance % (1000 * 60)) / 1000);
          // Display the result in the element with id="demo"
          document.getElementById("demo").innerHTML = minutes + "m " + seconds + "s "; // days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
          // If the count down is finished, write some text
          this.time_remaining = distance
          if (distance < 0) {
            clearInterval(x);
            localStorage.setItem('date-start', null)
            document.getElementById("demo").innerHTML = "TIME EXPIRED";
            localStorage.setItem('timer-status', 'false')
          }
        }, 1000);
      },
      post_outage () {
        this.bLoadOutage = true
      }
    },
    computed: {
      timer_begun () {
        return this.$store.state.timer_begun == 'true'
      },
      date_start () {
        return this.$store.state.date_start
      }
    }
  }
</script>
<style>
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: #F44335 !important;
}
.section_division_mobile {
  margin-top: 100px
}
.view_conatiner {
  padding: 0%
}
.section_division {
  margin-left: 22%;
}
.section_title {
  font-family: 'Open sans', sans-serif;
  font-size: 19px;
  color: #000000
}
.content_text {
  font-family: 'Open sans', sans-serif;
  font-size: 12px;
  color: #000000
}
.table_header_text {
  font-family: 'Open sans', sans-serif;
  font-size: 12px;
  font-weight: bold;
  color: #000000
}
.quick_add_wrapper {
  min-height: 85px;
  min-width: 100%;
  width: 80%;
  z-index: 1000;
  top: 20px;
  left: 18%;
  margin-bottom: 2%
}
.quick_add_wrapper_mobile {
  min-height: 50px;
  min-width: 90px;
  z-index: 1000;
  bottom: 0;
  right: 10px;
  margin-bottom: 2%
}
.alert_text {
  font-weight: bold;
  margin: 14px;
  color: white;
  font-family: 'Open sans', sans-serif;
}

</style>
